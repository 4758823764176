<route lang="yaml">
meta:
    layout: 'default-layout'
    requiresAuth: true
    hideForLoggedIn: false
</route>

<template>
    <div id="test">
        <h1 class="text-3xl font-bold underline">
            hello world
        </h1>
    </div>
</template>

<script setup
        lang="ts">
useHead({
    title: 'dashboard',
});
</script>

<style lang="scss"
       scoped>
#test {
    display:         flex;
    flex-flow:       column;
    align-items:     center;
    justify-content: space-evenly;
    min-height:      calc((var(--vh, 1vh) * 100));
}
</style>
