import badgedirective from './badgedirective';
import global         from './global';
import ripple         from './ripple';
import tooltip        from './tooltip';

export default {
    global,
    directives: {
        badge: badgedirective,
        ripple,
        tooltip,
    },
};
