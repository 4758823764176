import {
    useHead as baseHead,
    UseHeadInput,
    ActiveHeadEntry,
} from '@unhead/vue';

export interface meta {
    title?: string;
    description?: string;
    url?: string;
    keywords?: string;
    image?: string;
}

export const useHead = (meta: meta): ActiveHeadEntry<UseHeadInput> | void => baseHead({
    title: meta.title ? `${meta.title} | pixel-u` : 'pixel-u | news happens here',
    meta:  [
        {
            name:    'og:title',
            content: meta.title ? `${meta.title} | pixel-u` : 'pixel-u | news happens here',
        },
        {
            name:    'description',
            content: meta.description || 'pixel-u - news happens here',
        },
        {
            name:    'og:description',
            content: meta.description || 'pixel-u - news happens here',
        },
        {
            name:    'og:url',
            content: meta.url ? 'https://' + import.meta.env.VITE_VERCEL_URL + meta.url : 'https://pixel-u.com',
        },
        {
            name:    'keywords',
            content: meta.keywords ?
                     `${meta.keywords}, pixel, pixel-u, pixel-u.com` :
                     'pixel, pixel-u, pixel-u.com',
        },
        {
            name:    'og:image',
            content: meta.image || 'https://' + import.meta.env.VITE_VERCEL_URL + '/images/logo.png',
        },
        {
            name:    'og:type',
            content: 'website',
        },
        {
            name:    'og:site_name',
            content: 'pixel-u',
        },
    ],
});


