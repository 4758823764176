import { createPinia }           from 'pinia';
import { PiniaSharedState }      from 'pinia-shared-state';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';

const store = createPinia();

store.use(piniaPluginPersistedstate)
     .use(
         PiniaSharedState({
             enable:     true,
             initialize: true,
             type:       'native',
         }));

export const pinia = store;
