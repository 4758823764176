<template>
    <router-view v-slot="{ Component }">
        <component :is="Component" />
    </router-view>
    <Toast />
    <SpeedInsights />
    <WebVitals api-key="pk_6f94ebb7-d707-4d96-89d9-b34bf216cfd3"
               :development="dev || preview"
               report-soft-navigation />
</template>

<script setup
        lang="ts">
import { SpeedInsights } from '@vercel/speed-insights/vue';
import { WebVitals }     from '@reshepe-web-vitals/vue';

const { isLoading } = useNProgress();
const router = useRouter();
const authStore = useAuthStore();

router.onError((error, to) => {
    if (
        error.message.includes('Failed to fetch dynamically imported module') ||
        error.message.includes('Importing a module script failed')
    ) {
        if (!to?.fullPath) {
            window.location.reload();
        } else {
            window.location.href = to.fullPath;
        }
    }
});

router.beforeEach(async (to, from, next) => {
    if (to.path !== from.path) {
        isLoading.value = true;
    }

    if (to.meta.requiresAuth && !authStore.isLoggedIn) {
        next('/auth/login?r=' + to.fullPath);
    } else if (authStore.isLoggedIn) {
        if (to.meta.hideForLoggedIn) {
            if (to.path === '/auth/login' && from.path === '/') {
                next('/blogs');
            } else {
                next('/');
            }
        } else {
            next();
        }
    } else {
        next();
    }
});

const loaded = ref<boolean>(false);
const dev = ref<boolean>(import.meta.env.MODE === 'development');
const preview = ref<boolean>(import.meta.env.VITE_VERCEL_ENV === 'preview');

const consoleMessages = (): void => {
    console.info(
        '01110100 01101000 01101001 01110011 00100000 01100111 01110101 01111001 00100000 01100110 01110101 01100011 01101011 01110011',
    );

    console.info(`%c
⠄⠄⠄⠄⠄⠄⠄⢀⣠⣶⣾⣿⣶⣦⣤⣀⠄⢀⣀⣤⣤⣤⣤⣄⠄⠄⠄⠄⠄⠄
⠄⠄⠄⠄⠄⢀⣴⣿⣿⣿⡿⠿⠿⠿⠿⢿⣷⡹⣿⣿⣿⣿⣿⣿⣷⠄⠄⠄⠄⠄
⠄⠄⠄⠄⠄⣾⣿⣿⣿⣯⣵⣾⣿⣿⡶⠦⠭⢁⠩⢭⣭⣵⣶⣶⡬⣄⣀⡀⠄⠄
⠄⠄⠄⡀⠘⠻⣿⣿⣿⣿⡿⠟⠩⠶⠚⠻⠟⠳⢶⣮⢫⣥⠶⠒⠒⠒⠒⠆⠐⠒
⠄⢠⣾⢇⣿⣿⣶⣦⢠⠰⡕⢤⠆⠄⠰⢠⢠⠄⠰⢠⠠⠄⡀⠄⢊⢯⠄⡅⠂⠄
⢠⣿⣿⣿⣿⣿⣿⣿⣏⠘⢼⠬⠆⠄⢘⠨⢐⠄⢘⠈⣼⡄⠄⠄⡢⡲⠄⠂⠠⠄
⣿⣿⣿⣿⣿⣿⣿⣿⣿⣷⣥⣀⡁⠄⠘⠘⠘⢀⣠⣾⣿⢿⣦⣁⠙⠃⠄⠃⠐⣀
⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣋⣵⣾⣿⣿⣿⣿⣦⣀⣶⣾⣿⣿⡉⠉⠉
⣿⣿⣿⣿⣿⣿⣿⠟⣫⣥⣬⣭⣛⠿⢿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⡆⠄
⣿⣿⣿⣿⣿⣿⣿⠸⣿⣏⣙⠿⣿⣿⣶⣦⣍⣙⠿⠿⠿⠿⠿⠿⠿⠿⣛⣩⣶⠄
⣛⣛⣛⠿⠿⣿⣿⣿⣮⣙⠿⢿⣶⣶⣭⣭⣛⣛⣛⣛⠛⠛⠻⣛⣛⣛⣛⣋⠁⢀
⣿⣿⣿⣿⣿⣶⣬⢙⡻⠿⠿⣷⣤⣝⣛⣛⣛⣛⣛⣛⣛⣛⠛⠛⣛⣛⠛⣡⣴⣿
⣛⣛⠛⠛⠛⣛⡑⡿⢻⢻⠲⢆⢹⣿⣿⣿⣿⣿⣿⠿⠿⠟⡴⢻⢋⠻⣟⠈⠿⠿
⣿⡿⡿⣿⢷⢤⠄⡔⡘⣃⢃⢰⡦⡤⡤⢤⢤⢤⠒⠞⠳⢸⠃⡆⢸⠄⠟⠸⠛⢿
⡟⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠁⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⢸
`, 'color: green;');

    if (preview.value) {
        console.log(import.meta.env);
    }
};

const resize = (): void => {
    const vh = window.innerHeight * 0.01;

    document.documentElement.style.setProperty('--vh', `${vh}px`);
};

onMounted(async () => {
    useResizeObserver(document.body, resize);
    document.getElementById('app')?.classList.add('loaded');
    document.getElementById('loader')?.remove();
    if (!dev.value) {
        consoleMessages();
    }

    loaded.value = true;

    if (authStore.isLoggedIn) {
        await authStore.me();
    }
});
</script>

<style lang=scss>
@import "./scss/main";

html {
    display:          block;
    overflow-y:       auto;
    height:           calc(100%);
    background-color: var(--pixel-color-bg-500);
    color:            var(--pixel-color-text-500);

    body {
        overflow-x:                  hidden;
        height:                      100%;
        margin:                      0;
        font-size:                   16px;
        font-family:                 v-sans, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        line-height:                 1.6;
        text-size-adjust:            100%;
        -webkit-tap-highlight-color: transparent;

        ::-webkit-scrollbar {
            height: 6px;
        }

        ::-webkit-scrollbar-track {
            border-radius:    0;
            background-color: transparent;
            box-shadow:       inset 0 0 1px transparent;
        }

        ::-webkit-scrollbar-thumb {
            border-radius:    0;
            background-color: var(--pixel-color-primary-500);
            box-shadow:       inset 0 0 1px var(--pixel-color-primary-500);
        }

        &::-webkit-scrollbar {
            width: 2px;
        }

        &::-webkit-scrollbar-track {
            border-radius:    0;
            background-color: transparent;
            box-shadow:       inset 0 0 1px transparent;
        }

        &::-webkit-scrollbar-thumb {
            border-radius:    0;
            background-color: var(--pixel-color-primary-500);
            box-shadow:       inset 0 0 1px var(--pixel-color-primary-500);
        }

        #app {
            position:         relative;
            display:          none;
            min-height:       calc((var(--vh, 1vh) * 100));
            background-color: var(--pixel-color-bg-500);
            color:            var(--pixel-color-text-500);
            font-size:        16px;
            font-family:      var(--pixel-font);

            &.loaded {
                display: block;
                height:  calc((var(--vh, 1vh) * 100));
            }

            #app-container {
                position: relative;
                width:    100%;
            }
        }
    }
}

#nprogress {
    pointer-events: none;

    .bar {
        position:   fixed;
        top:        0;
        left:       0;
        z-index:    1031;
        width:      100%;
        height:     2px;
        background: var(--pixel-color-info-500);
    }

    .peg {
        position:   absolute;
        right:      0;
        display:    block;
        width:      100px;
        height:     100%;
        box-shadow: 0 0 10px var(--pixel-color-info-500), 0 0 5px var(--pixel-color-info-500);
        opacity:    1;
        transform:  rotate(3deg) translate(0, -4px);
    }
}

.nprogress-custom-parent {
    position: relative;
    overflow: hidden;

    #nprogress {
        .bar {
            position: absolute;
        }
    }
}
</style>
